import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import Grid from "@material-ui/core/Grid";
import DataChartA from "../components/trends2021/DataChartA";
import DataChartB from "../components/trends2021/DataChartB";
import DataChartC from "../components/trends2021/DataChartC";
import DataChartD from "../components/trends2021/DataChartD";
import DataChartE from "../components/trends2021/DataChartE";
import DataChartF from "../components/trends2021/DataChartF";

import FurtherReading from "../components/furtherReading"

const AfroHairTrends2021 = () => (
  <Layout>
    <SEO
      title="Afro Hair Trends 2021"
      description="A look into the popularity of black hair styles, trends and techniques for the past year. Find out how your searches compare to other people around the world."
    />
    <h1>Afro Hair Trends 2021</h1>
    <h2 style={{color:"Orchid"}}>A look into the popularity of black hair styles, trends and care techniques for the past year</h2>
    <DataChartB />
    <p>We thought it would be interesting to do a study on the popularity of search terms used by our community in 2020 - <a
        href="https://trends.google.com/trends/explore?geo=US&q=box%20braids,dreadlocks,natural%20hair,hair%20weave"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      according to Google
      </a> . This study is hopefully as useful to us as it is to you guys. Us because it lets us know what content to write about and you because it gives you an insight into what other people search and how you compare.</p>
    <p>We picked a few categories based on the things our readers search like the type of hair styles you are interested in and how you describe your own hair. Some people say black hair, others say afro hair.</p>
    <p>We get readers from all over the world and the most popular countries by far are the United States (USA), United Kingdom (UK), South Africa (RSA), Nigeria (NG) and Canada (CA). Click each country code to switch between data sets.</p>
    <p>The study was fun to do and let us know some things we had no idea about. It's great to see how even though we share the same hair types, culturally, we can be very different depending on where we live.</p>
    <p>Please share this with your friends, family and groups if you find it interesting. It will help us out a lot and let us do more things like this in future.</p>

    <h2>Hair Styles</h2>
    <h3 style={{color:"Orchid"}}>Box braids, dreadlocks, natural hair, weave</h3>
    <DataChartA />
    <p>We wanted to see how a few popular hair styles compared. Braids covered a huge range of styles so we narrowed it down to <Link to="/tips-on-box-braids-for-black-hair/" style={{ textDecoration: "none" }}>box braids</Link>. The word weave can be applied to topics other than hair so we chose hair weave.</p>
    <p>Natural hair is the post popular in all countries and weave being the least popular. Box braids is the next most popular search in the US while in other regions it was even with or around dreadlocks and weave. Not too much of a cultural difference here but it is very clear that there is a big demand for information on natural hair. Which of course, we love the idea of!</p>

    <h2>Hair styles - part 2</h2>
    <h3 style={{color:"Orchid"}}>Big chop, get locs, buy a wig, natural hair care, relax hair</h3>
    <DataChartB />
    <p>To mix it up a bit, we chose similar data to the previous chart and changed the terms to show different intent. The first chart’s terms could be viewed as someone looking for ideas and inspiration. With this one the person has likely decided what they want and is ready to take action.</p>
    <p>One thing to note is that because of the cultural difference and use of words, we had to try the words “dreadlocks” and “locs” for each region. Then we used the data for the most popular term.</p>
    <p>Natural terms like big chop and natural hair care lead in America and Canada. By a much bigger margin in America. Not surprisingly because there is a huge movement right now and more & more people are choosing to embrace their natural self.</p>
    <p>Buy a wig is the least popular in all countries with it not even registering in South Africa and Nigeria. Relaxing hair is quite consistent throughout. It’s actually joint first with natural hair care in South Africa. Canada is the most diverse place with no one style beating the other by a large margin.</p>

    <h2>Weave or Braid</h2>
    <h3>weaving hair, braiding hair, weave hair, braid hair</h3>
    <DataChartC />
    <p>This one lets us see the difference two ways to style hair, braid and weave. Plus the different types of intent. “Weaving hair” leans more towards do it yourself while “Weave hair” implies the same but could also mean get someone else to do it.</p>
    <p>Surprisingly, nearly each country had its own most popular term with only Canada and the UK sharing “braid hair” in the top spot.</p>
    <p>It seems Nigerians are most likely to weave their own hair, Americans are most likely to braid their own hair and getting your hair braided for you is most popular in the UK and Canada.</p>

    <h2>Wig Types</h2>
    <h3 style={{color:"Orchid"}}>synthetic hair wig, human hair wig, animal hair wig</h3>
    <DataChartD />
    <p>We knew human hair is the most desirable but we surprised to see by how much. Human hair wigs are the most expensive, look the best and last the longest (with care). The USA has the biggest difference in popularity between human and synthetic. Synthetic doesn’t even register in South Africa and Nigeria.</p>
    <p>Also no surprise, no one searches for animal hair wigs making them the least desirable. They are however right in the middle of synthetic and human in terms of price. We’ve got a whole article <Link to="/wigs-for-black-women/" style={{ textDecoration: "none" }}>
    all about wigs
    </Link> if you want to learn more.</p>
    <p>This data surprised me so much that I though people are probably search for “curly wig”, “straight wig” etc. and ending up buying synthetic.</p>

    <h2>How We Describe Our Hair</h2>
    <h3 style={{color:"Orchid"}}>natural hair products, afro hair products, black hair products, curly hair products</h3>
    <DataChartE />
    <p>This one shows the cultural difference in how we all describe our own hair. It was a bit tricky because we chose to leave out terms like kinky, coily etc. for the most popular. Curly hair is also inclusive of more ethnicities so naturally the term should be more popular.</p>
    <p>On the bar chart I used “+” to replace the word product to make it all fit better.</p>
    <p>In the western countries; Canada and USA and UK, curly hair is the most popular. Natural hair is most popular in South Africa and Nigeria. People in every country here are more likely to refer to their own hair as black than afro.</p>
    <p>In the UK the terms natural hair, afro hair and black hair are much closer together than anywhere else so you are likely to hear all three terms in every day conversation.</p>

    <h2>Popular Products</h2>
    <h3 style={{color:"Orchid"}}>Cantu products, Shea Moisture products, African pride products, Maui products, Aunt Jackie’s products</h3>
    <DataChartF />
    <p>This one is pretty straight forward. Which products are the most popular. Note that lots of ethnicities use these products and they are not just for natural hair. It was also difficult to choose which brands to go for, we’ll discuss a bit further on.</p>
    <p>Cantu and Shea Moisture are the most popular products in every county here.   With Cantu leading everywhere but the US. The smaller brands share a little popularity with Aunt Jackie’s and African Pride not even registering in Nigeria and Canada.</p>
    <p>One important thing to note which I mentioned in the introduction is that the numbers on the left hold meaning. If you look at Shea Moisture for the USA it is just below 50 meaning the product is half as popular as it ever has been. In the UK it is under a quarter as popular as ever.</p>
    <p>We still need to condition our hair so my theory for the “lack” of popularity is that we are moving more towards homemade conditioners or we settled with what we are already using so no longer need to search for information.</p>
    <p>Back to choosing the brands, we chose brands that had many products in their range that we also thought had good reach throughout the world. Turns out some don’t. There are vastly more popular products but they are generally known for 1 or 2 products.</p>
    <p>Go check out our growing <Link to="/black-owned-hair-products/" style={{ textDecoration: "none" }}>list of black owned hair products</Link> and support the people building businesses in the black community for the black community.</p>


    <h2>Thanks for reading</h2>
    <h3 style={{color:"Orchid"}}>Remember to share this page, it really does help a lot. Thank you!</h3>


    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p> 
          <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>                  
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default AfroHairTrends2021
