import React, { useState } from "react";

import Chart from "chart.js";
import { Bar } from "react-chartjs-2";

import Button from "@material-ui/core/Button";

import ButtonGroup from "@material-ui/core/ButtonGroup";

function DataChartB() {
  // Bar chart

  // the data has to come before the chart
  const labelData = [
    "big chop",
    "get locs",
    "buy a wig",
    "natural hair care",
    "relax hair"
  ];

  const [valueData, setValueData] = useState([47, 21, 12, 53, 28]);

  const barData = {
    labels: labelData,
    datasets: [
      {
        label: "Data",
        backgroundColor: [
          "#3e95cd",
          "#8e5ea2",
          "#3cba9f",
          "#e8c3b9",
          "#c45850"
        ],
        // borderColor: "rgba(0,0,0,1)",
        // borderWidth: 2,
        data: valueData
      }
    ]
  };

  // console.log(barData.datasets[0].data);
  // console.log(valueData);

  const [region, setRegion] = useState("USA");

  // region == "USA" ? console.log("true") : console.log("false");

  function USAData() {
    setValueData([47, 21, 12, 53, 28]);
    setRegion("USA");
  }
  function UKData() {
    setValueData([17, 18, 8, 22, 26]);
    setRegion("UK");
  }
  function RSAData() {
    setValueData([17, 4, 0, 26, 26]);
    setRegion("RSA");
  }
  function NGData() {
    setValueData([6, 0, 0, 20, 18]);
    setRegion("NG");
  }
  function CAData() {
    setValueData([18, 12, 11, 19, 16]);
    setRegion("CA");
  }

  return (
    <div style={{ height: "350px", paddingBottom: "50px", marginBottom:"15px" }}>
      <Bar
        data={barData}
        options={{
          maintainAspectRatio: false,
          title: {
            display: false,
            text: "Title",
            fontSize: 20
          },
          legend: {
            display: false,
            position: "top"
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 60
                }
              }
            ]
          }
        }}
      />

      {/* <button onClick={handleClick} id="the button">
        Click me
      </button> */}

      <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <ButtonGroup size="small" aria-label="small outlined button group">
          {/* USA */}
          <Button
            disableElevation
            onClick={USAData}
            color={region === "USA" ? "primary" : null}
            variant={region === "USA" ? "contained" : null}
          >
            USA
          </Button>
          {/* UK */}
          <Button
            disableElevation
            onClick={UKData}
            color={region === "UK" ? "primary" : null}
            variant={region === "UK" ? "contained" : null}
          >
            UK
          </Button>
          {/* SA */}
          <Button
            disableElevation
            onClick={RSAData}
            color={region === "RSA" ? "primary" : null}
            variant={region === "RSA" ? "contained" : null}
          >
            RSA
          </Button>
          {/* NG */}
          <Button
            disableElevation
            onClick={NGData}
            color={region === "NG" ? "primary" : null}
            variant={region === "NG" ? "contained" : null}
          >
            NG
          </Button>
          {/* CA */}
          <Button
            disableElevation
            onClick={CAData}
            color={region === "CA" ? "primary" : null}
            variant={region === "CA" ? "contained" : null}
          >
            CA
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default DataChartB;
