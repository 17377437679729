import React, { useState } from "react";

import Chart from "chart.js";
import { Bar } from "react-chartjs-2";

import Button from "@material-ui/core/Button";

import ButtonGroup from "@material-ui/core/ButtonGroup";

function DataChartD() {
  // Bar chart

  // the data has to come before the chart
  const labelData = ["synthetic hair wig", "human hair wig", "animal hair wig"];

  const [valueData, setValueData] = useState([8, 59, 0]);

  const barData = {
    labels: labelData,
    datasets: [
      {
        label: "Data",
        backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f"],
        // borderColor: "rgba(0,0,0,1)",
        // borderWidth: 2,
        data: valueData
      }
    ]
  };

  // console.log(barData.datasets[0].data);
  // console.log(valueData);

  const [region, setRegion] = useState("USA");

  // region == "USA" ? console.log("true") : console.log("false");

  function USAData() {
    setValueData([8, 59, 0]);
    setRegion("USA");
  }
  function UKData() {
    setValueData([6, 36, 0]);
    setRegion("UK");
  }
  function RSAData() {
    setValueData([0, 24, 0]);
    setRegion("RSA");
  }
  function NGData() {
    setValueData([0, 29, 0]);
    setRegion("NG");
  }
  function CAData() {
    setValueData([6, 29, 0]);
    setRegion("CA");
  }

  return (
    <div style={{ height: "350px", paddingBottom: "50px", marginBottom:"15px" }}>
      <Bar
        data={barData}
        options={{
          maintainAspectRatio: false,
          title: {
            display: false,
            text: "Title",
            fontSize: 20
          },
          legend: {
            display: false,
            position: "top"
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 60
                }
              }
            ]
          }
        }}
      />

      {/* <button onClick={handleClick} id="the button">
        Click me
      </button> */}

      <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <ButtonGroup size="small" aria-label="small outlined button group">
          {/* USA */}
          <Button
            disableElevation
            onClick={USAData}
            color={region === "USA" ? "primary" : null}
            variant={region === "USA" ? "contained" : null}
          >
            USA
          </Button>
          {/* UK */}
          <Button
            disableElevation
            onClick={UKData}
            color={region === "UK" ? "primary" : null}
            variant={region === "UK" ? "contained" : null}
          >
            UK
          </Button>
          {/* SA */}
          <Button
            disableElevation
            onClick={RSAData}
            color={region === "RSA" ? "primary" : null}
            variant={region === "RSA" ? "contained" : null}
          >
            RSA
          </Button>
          {/* NG */}
          <Button
            disableElevation
            onClick={NGData}
            color={region === "NG" ? "primary" : null}
            variant={region === "NG" ? "contained" : null}
          >
            NG
          </Button>
          {/* CA */}
          <Button
            disableElevation
            onClick={CAData}
            color={region === "CA" ? "primary" : null}
            variant={region === "CA" ? "contained" : null}
          >
            CA
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default DataChartD;
